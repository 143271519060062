<template>
  <v-card class="mx-auto peeps--font">
    <v-card-title class="display-1 peeps--text card-title">
      <slot name="baseTitle"> Hooray!<slot name="title"></slot> </slot>
    </v-card-title>
    <slot name="image">
      <v-img
        max-width="200"
        class="mx-auto"
        src="@/assets/img/PeepsHooray.svg"
      ></v-img>
    </slot>
    <v-card-text>
      <slot></slot>
    </v-card-text>
    <v-card-actions>
      <slot name="action"></slot>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {}
</script>

<style scoped>
.card-title {
  font-weight: 500 !important;
}
</style>
